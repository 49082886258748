import img from './img/image.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={img} className={'img'} alt="img" />
    </div>
  );
}

export default App;
